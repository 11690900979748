import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import WelcomeView from '../views/WelcomeView.vue'
import TiempoMesas from '../views/TiempoMesas.vue'
import Details from '../views/DetailsView.vue'
import Detalles from '../views/DetallesCobra.vue'
import CategoriaProducto from '../views/CategoriaProducto.vue'
import Inventory from '../views/InventoryView.vue'
import DetallesInventory from '../views/DetallesInventario.vue'
import Waitress from '../views/WaitressView.vue'
import Order from '../views/OrdenView.vue'
import OrderDetalles from '../views/OrdenDetalles.vue'
import Camarera from '../views/CamareraView.vue'
import CamareraDetalles from '@/views/CamareraDetalles.vue'
import ResumenVentda from '../views/ResumenVenta.vue'
import HistorialRecaudos from '../views/HistorialRecaudos.vue'
import EstadoMesas from '../views/EstadoMesas.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: WelcomeView
  },
  {
    path: '/estado',
    name: 'estadomesas',
    component: EstadoMesas
  },
  {
    path: '/tiempo',
    name: 'tiempo',
    component: TiempoMesas,
  },
  {
    path: '/details',
    name: 'details',
    component: Details
  },
  {
    path: '/detalles',
    name: 'detalles',
    component: Detalles
  },
  {
    path: '/inventario',
    name: 'categoriaproducto',
    component: CategoriaProducto
  },
  {
    path: '/detallesinventory',
    name: 'detallesinventory',
    component: DetallesInventory
  },
  {
    path: '/bodega',
    name: 'inventory',
    component: Inventory
  },
  {
    path: '/waitress',
    name: 'waitress',
    component: Waitress
  },
  {
    path: '/pedidos/registrar',
    name: 'order',
    component: Order  
  },
  {
    path: '/pedidos/detalles',
    name: 'orderdetalles',
    component: OrderDetalles
  },
  {
    path: '/camarera',
    name: 'camarera',
    component: Camarera
  },
  {
    path: '/camareradetalles',
    name: 'camareradetalles',
    component: CamareraDetalles
  },
  {
    path: '/resumenventa',
    name: 'resumenventa',
    component: ResumenVentda
  },
  {
    path: '/historialrecaudos',
    name: 'HistorialRecaudos',
    component: HistorialRecaudos
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
