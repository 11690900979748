<template>
  <div class="tiempo-mesas">
    <h1>Tiempo de Mesas</h1>

    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

    <div class="total-general">
      <h2>Total TIempo: {{ formatTime(totalGeneral.tiempo) }}</h2>
      <h2>Total Dinero: {{ formatCurrency(totalGeneral.dinero) }}</h2>
    </div>
    <button @click="confirmarMarcarSeleccionadosPagados" class="mark-paid-button">Marcar Seleccionados como Pagados</button>
    <div class="dias-container">
      <div class="dia-card" v-for="dia in dias" :key="dia.fecha">
        <div class="dia-header">
          <input type="checkbox" v-model="selectedDias" :value="dia.fecha">
          <h2>{{ dia.fecha }}</h2>
        </div>
        <p>Total Tiempo: {{ formatTime(dia.total_tiempo) }}</p>
        <p>Total Dinero: {{ formatCurrency(dia.total_dinero) }}</p>
      </div>
    </div>


    <div v-if="showModal" class="modal-overlay">
      <div class="modal-container">
        <h3>Mensaje</h3>
        <p>{{ modalMessage }}</p>
        <button v-if="modalAction" @click="modalAction">Sí</button>
        <button @click="showModal = false">Cerrar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../plugins/axios'; // Asegúrate de que la ruta sea correcta

export default {
  data() {
    return {
      dias: [],
      totalGeneral: {
        tiempo: 0,
        dinero: 0,
      },
      errorMessage: '',
      showModal: false,
      modalMessage: '',
      modalAction: null,
      selectedDias: [], // Estado para los días seleccionados
    };
  },
  mounted() {
    this.fetchDataPorDia();
  },
  methods: {
    async fetchDataPorDia() {
      try {
        const response = await axios.get('/tiempo-mesas/por-dia-grupo');
        if (response && response.data) {
          const data = response.data;
          this.dias = data.tiempo_por_dia.map(dia => ({
            fecha: dia.fecha,
            total_tiempo: dia.total_tiempo,
            total_dinero: dia.total_dinero,
          }));
          // Calcular el total general sumando los totales de cada día
          this.totalGeneral = {
            tiempo: this.dias.reduce((acc, dia) => acc + dia.total_tiempo, 0),
            dinero: this.dias.reduce((acc, dia) => acc + dia.total_dinero, 0),
          };
          this.errorMessage = ''; // Limpiar cualquier mensaje de error previo
        } else {
          this.errorMessage = 'Datos no recibidos correctamente.';
          this.showModalMessage(this.errorMessage);
        }
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        this.errorMessage = error.response?.data?.message || 'Error al obtener los datos';
        this.showModalMessage(this.errorMessage);
      }
    },
    showModalMessage(message, action = null) {
      this.modalMessage = message;
      this.modalAction = action;
      this.showModal = true;
    },
    confirmarMarcarSeleccionadosPagados() {
      if (this.selectedDias.length === 0) {
        this.showModalMessage('No has seleccionado ningún día.');
        return;
      }
      this.showModalMessage('¿Estás seguro de que quieres marcar los días seleccionados como pagados?', this.marcarSeleccionadosPagados);
    },
    marcarSeleccionadosPagados() {
      axios.post('/tiempo-mesas/marcar-pagados', {
        fechas: this.selectedDias,
      })
        .then(response => {
          const data = response.data;
          if (data.success) {
            this.fetchDataPorDia(); // Refrescar los datos después de marcar como pagados
            this.showModal = false;
            this.showModalMessage('Los días seleccionados han sido marcados como pagados');
          } else {
            console.error('Error al marcar los días como pagados:', data.message);
            this.showModalMessage('Error al marcar los días como pagados: ' + data.message);
          }
        })
        .catch(error => {
          console.error('Error al marcar los días como pagados:', error);
          this.showModalMessage('Error al marcar los días como pagados: ' + error.message);
        });
    },
    formatTime(hours) {
      const totalSeconds = hours * 3600;
      const h = Math.floor(totalSeconds / 3600);
      const m = Math.floor((totalSeconds % 3600) / 60);
      const s = Math.floor(totalSeconds % 60);
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value);
    }
  }
};
</script>

<style scoped>
.tiempo-mesas {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.total-general {
  margin-bottom: 20px;
}

.dias-container {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.dia-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
}

.dia-header {
  /* display: flex; */
  align-items: center;
}

.dia-header h2 {
  margin-left: 10px;
}

p {
  margin: 5px 0;
}

.error-message {
  color: red;
  font-weight: bold;
}

.mark-paid-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 10px 10px 10px;
}

.mark-paid-button:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
</style>
