<template>
  <div class="login-container">
    <h2>Iniciar seccion</h2>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required>
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="password" required>
      </div>
      <button type="submit">Login</button>
      <p v-if="error" class="error">{{ error }}</p>
    </form>
  </div>
</template>

<script>
import axios from '../plugins/axios'; // Asegúrate de que la ruta sea correcta

export default {
  data() {
    return {
      email: '',
      password: '',
      error: null
    };
  },
  methods: {
    async login() {
      try {
        this.error = null;
        const response = await axios.post('/auth/login', {
          email: this.email,
          password: this.password
        });

        // Almacena el token en el localStorage
        localStorage.setItem('token', response.data.access_token);
        // Redirecciona a la página de inicio
        this.$router.push({ name: 'welcome' });
      } catch (error) {
        console.error('Error details:', error); // Muestra detalles del error en la consola
        if (error.response) {
          // El servidor respondió con un código de estado fuera del rango 2xx
          console.error('Response error data:', error.response.data);
          console.error('Response error status:', error.response.status);
          console.error('Response error headers:', error.response.headers);
          if (error.response.status === 401) {
            this.error = 'Email o contraseña incorrectos';
          } else if (error.response.status === 500) {
            this.error = 'Ocurrió un error en el servidor. Inténtelo de nuevo más tarde.';
          } else {
            this.error = `Error: ${error.response.status} - ${error.response.data.message || 'Ocurrió un error desconocido. Inténtelo de nuevo.'}`;
          }
        } else if (error.request) {
          // La solicitud fue hecha pero no se recibió respuesta
          console.error('Request error data:', error.request);
          this.error = 'No se pudo conectar con el servidor. Por favor, verifique su conexión a internet.';
        } else {
          // Algo sucedió al configurar la solicitud
          console.error('Error message:', error.message);
          this.error = `Error en la configuración de la solicitud: ${error.message}`;
        }
      }
    }
  }
}
</script>


<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100vh;
  /* background-color: #f7f7f7; */
  padding: 20px;
}

h2 {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #42b983;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #36a373;
}

.error {
  color: red;
  margin-top: 10px;
}

/* Estilos responsivos */
@media (max-width: 600px) {
  .login-container {
    padding: 10px;
  }

  form {
    padding: 15px;
  }

  h2 {
    font-size: 1.5em;
  }
}
</style>
